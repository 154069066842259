.popup__save-button_disabled {
  border: 1px solid #000000;
  background-color: transparent;
  color: #000000;
  cursor: not-allowed;
  opacity: .2;
}

.popup__save-button_disabled:hover {
  opacity: .2;
}
