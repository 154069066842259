.card__like-button {
  width: 22px;
  height: 19px;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  background-image: url(../../../images/icon__like.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .2s;
}

.card__like-button:hover {
  opacity: .5;
}
