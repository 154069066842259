.card__title {
  margin: 0; /* Сброс стандартных стилей */
  color: #000;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.21;
  /* При переполнении текст обрезается */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
