.login__title {
  margin: 0;
  margin-bottom: 50px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;
}

@media (max-width: 768px) {
  .login__title {
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 24px;
  }
}
