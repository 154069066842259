.card__delete-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 18px;
  height: 19px;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  background-image: url(../../../images/icon__trash.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .2s;
}

.card__delete-button:hover {
  opacity: .6;
}
