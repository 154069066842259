.popup__title {
  margin: 0; /* Сброс стандартных стилей */
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.21;
  color: #000000;
}

@media screen and (max-width: 768px) {
  .popup__title {
    font-size: 18px;
    line-height: 1.22;
  }
}
