/* Центрирование всех секций страницы по центру с полями справа и слева */
.content__element {
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 2 * 200px); /* Поля 200px */
}

@media screen and (max-width: 1024px) {
  .content__element {
    width: calc(100% - 2 * 72px); /* Поля 72px */
  }
}

@media screen and (max-width: 768px) {
  .content__element {
    width: calc(100% - 2 * 19px); /* Поля 19px */
  }
}
