.footer__copyright {
  margin: 0; /* Сброс стандартного стиля */
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.22;
  color: #545454;
}

@media screen and (max-width: 768px) {
  .footer__copyright {
    font-size: 14px;
    line-height: 1.21;
  }
}
