.login__input {
  padding: 0;
  padding-bottom: 13px;
  border: none;
  border-bottom: 2px solid #CCCCCC;
  background-color: transparent;
  margin-bottom: 30px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}

.login__input:last-of-type {
  margin-bottom: 0;
}

.login__input::placeholder {
  font-weight: 400;
  color: #CCCCCC;
}

.login__input:focus {
  outline-offset: 3px;
}
