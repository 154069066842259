.login__extra-text {
  margin: 0;
  padding-block: 20px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #FFFFFF;
}
