.profile__avatar-button {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  border: none;
  border-radius: 50%;
  background-image: url(../../../images/icon__edit.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 26px;
  background-color: rgba(0, 0, 0, .8);
  cursor: pointer;
  opacity: 0;
  transition: opacity .2s;
}

.profile__avatar-button:hover {
  opacity: 1;
}
