.popup__container {
  /* Относительное позиционирование для кнопки закрытия */
  position: relative;
  /* Предельные размеры по макету */
  max-width: 430px;
  box-sizing: border-box;
  /* Оформление */
  background-color: #fff;
  border-radius: 10px;
  color: #000;
  padding: 34px 36px 37px;
  box-shadow: 0 0 25px rgba(0, 0, 0, .15);
}

@media screen and (max-width: 768px) {
  .popup__container {
    padding: 25px 22px 25px;
  }
}
