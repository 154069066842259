.profile__name-block {
  display: flex;
  gap: 18px;
  align-items: baseline; /* Выравнивание кнопке редактирования по строке текста */
}

@media screen and (max-width: 768px) {
  .profile__name-block {
    /* Отступ слева для компенсации кнопки справа при выравинвании по центру */
    padding-left: calc(18px + 10px);
    gap: 10px;
    justify-content: center;
  }
}
