.login__submit-button {
  cursor: pointer;
  margin-top: 216px;
  padding: 14px;
  border: none;
  border-radius: 2px;
  background-color: #fff;
  transition: opacity .3s;
  /* Text */
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}

@media (max-width: 768px) {
  .login__submit-button {
    margin-top: 173px;
    font-size: 16px;
    line-height: 19px;
  }
}

.login__submit-button:hover {
  opacity: .85;
}
