.popup__save-button {
  margin-top: 18px;
  /* Размеры */
  width: 100%;
  height: 50px;
  /* Оформление */
  cursor: pointer;
  border: 0;
  border-radius: 2px;
  background-color: #000;
  /* Параметры шрифта текста внутри */
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.22;
  text-align: center;
  color: #FFFFFF;
  /* Анимация при наведении */
  transition: opacity .2s;
}

.popup__save-button:hover {
  opacity: .8;
}

@media screen and (max-width: 768px) {
  .popup__save-button {
    height: 46px;
    font-size: 14px;
    line-height: 1.21;
  }
}

.popup__title + .popup__save-button {
  margin-top: 30px;
}
