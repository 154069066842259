.profile__button_type_add {
  width: 150px;
  height: 50px;
  border-width: 2px;
  border-radius: 2px;
  background-image: url(../../../../images/icon__add.svg);
  background-size: 22px 22px;
}

@media screen and (max-width: 768px) {
  .profile__button_type_add {
    background-size: 16px 16px;
  }
}

@media screen and (max-width: 425px) {
  .profile__button_type_add {
    width: 100%;
  }
}
