.profile__button {
  flex-shrink: 0;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid #fff;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity .2s;
}

.profile__button:hover {
  opacity: .6;
}
